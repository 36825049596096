angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('signEmail', {
            url: '/signDocument/email/:token',
            views: {
                "content": {
                    controller: 'SignEmailCtrl',
                    templateUrl: 'web/sign/email/email.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Sign Document'
            }
        });
    })
    .controller('SignEmailCtrl', function ($rootScope, $scope, session, signatureService, $stateParams, $sce, $timeout, $translate, companyService, $window) {
        var that = this;

        ////////////////////////
        // Inicio INICIALIZAR
        ///////////////////////
        that.token = $stateParams.token;
        that.pageURLS = [];

        function storePage(i, page) {
            that.pageURLS[i] = page;
        }

        signatureService.retrieveFirmaByToken($stateParams.token).then(function (response) {
            that.loaded = true;
            that.sign = response.data.signature;
            that.firmaMultiple = response.data.signatureSinger;
            for (var k = 0; k < that.sign.signers.length; k++) {
                if (that.sign.signers[k].id == that.firmaMultiple.id) {
                    that.actualFirmante = that.sign.signers[k];
                }
            }

            function loadPage(i) {
                signatureService.getTokenURL($stateParams.token, that.sign.id, i).then(function (response) {
                    that.pageURLS[i] = response.data;
                });
            }
            for (var i = 0; i < that.sign.pages; i++) {
                loadPage(i);
            }
            
            if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                $window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Signatures',
                    eventAction: 'sign',
                    eventLabel: 'Email'
                });
            }
        });


        that.original = function () {
            signatureService.getTokenDownloadURLbyToken($stateParams.token).then(function (response) {
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', 'https://api.' + $rootScope.dominio + '/signatures/onlySigned/' + response.data);
                downloadLink.attr('download', 'evidences.pdf');
                downloadLink[0].click();
            });
        };

        that.trustSrc = function (src) {
            return $sce.trustAsResourceUrl(src);
        };
    });
